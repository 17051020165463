<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M15.499,19.672c-1.619-0.634-2.061-0.938-2.155-0.996v-0.032c0.197-0.246,0.354-0.545,0.463-0.88
      c0.157-0.162,0.261-0.374,0.293-0.602c0.031-0.232-0.011-0.462-0.12-0.663v-0.651c0-1.182-0.744-1.888-1.99-1.888
      c-1.228,0-1.99,0.724-1.99,1.888v0.651c-0.109,0.201-0.151,0.43-0.12,0.662c0.031,0.228,0.135,0.439,0.292,0.603
      c0.109,0.334,0.267,0.634,0.464,0.88v0.005c-0.145,0.118-0.622,0.422-2.155,1.023c-0.286,0.11-0.478,0.394-0.478,0.703v0.892
      c0,0.416,0.335,0.754,0.747,0.754h6.481c0.412,0,0.747-0.338,0.747-0.754v-0.892C15.978,20.065,15.785,19.782,15.499,19.672z
      M14.978,21.02H9.002v-0.479c2.019-0.802,2.49-1.189,2.611-1.581c0.015-0.048,0.022-0.098,0.022-0.147v-0.356
      c0-0.13-0.05-0.255-0.141-0.348c-0.176-0.182-0.319-0.45-0.403-0.757c-0.03-0.11-0.109-0.325-0.202-0.392
      C10.961,16.871,11,16.76,11,16.646v-0.799c0-0.605,0.314-0.888,0.99-0.888c0.685,0,0.99,0.273,0.99,0.888v0.799
      c0,0.114,0.028,0.342,0.1,0.431c-0.094,0.066-0.162,0.163-0.192,0.274c-0.084,0.307-0.228,0.576-0.403,0.757
      c-0.09,0.094-0.14,0.218-0.14,0.348v0.356c0,0.05,0.007,0.1,0.022,0.146c0.121,0.394,0.592,0.782,2.611,1.582V21.02z"/>
    <path
      d="M23.5,8.67c-1.62-0.634-2.061-0.938-2.155-0.996V7.642c0.197-0.247,0.354-0.545,0.463-0.879
      c0.156-0.162,0.26-0.372,0.292-0.599c0.032-0.233-0.01-0.463-0.119-0.666V4.847c0-1.182-0.744-1.888-1.99-1.888
      c-1.228,0-1.99,0.724-1.99,1.888v0.651c-0.11,0.202-0.152,0.432-0.12,0.664c0.032,0.228,0.136,0.438,0.291,0.6
      c0.109,0.334,0.267,0.633,0.464,0.88v0.005c-0.145,0.118-0.621,0.422-2.156,1.024c-0.285,0.112-0.477,0.394-0.477,0.702v0.892
      c0,0.416,0.335,0.754,0.747,0.754h6.481c0.412,0,0.747-0.338,0.747-0.754V9.368C23.976,9.056,23.788,8.782,23.5,8.67z
      M22.979,10.019h-5.976v-0.48c2.019-0.801,2.49-1.189,2.61-1.581c0.015-0.048,0.022-0.097,0.022-0.147V7.455
      c0-0.129-0.05-0.254-0.14-0.347c-0.177-0.183-0.319-0.452-0.403-0.757c-0.031-0.111-0.108-0.326-0.202-0.393
      C18.961,5.869,19,5.759,19,5.645V4.847c0-0.606,0.314-0.888,0.99-0.888c0.685,0,0.99,0.274,0.99,0.888v0.798
      c0,0.113,0.029,0.341,0.101,0.43c-0.095,0.067-0.162,0.164-0.193,0.275c-0.084,0.306-0.227,0.575-0.403,0.757
      c-0.09,0.093-0.14,0.218-0.14,0.347V7.81c0,0.05,0.008,0.099,0.022,0.147c0.12,0.393,0.592,0.781,2.611,1.582v0.48H22.979z"/>
    <path
      d="M7.499,8.67c-1.62-0.634-2.061-0.938-2.155-0.996V7.642C5.54,7.396,5.698,7.097,5.807,6.763
      c0.157-0.162,0.26-0.374,0.292-0.601C6.131,5.93,6.089,5.7,5.979,5.497v-0.65c0-1.182-0.744-1.888-1.99-1.888
      C2.762,2.959,2,3.682,2,4.847v0.65C1.89,5.699,1.848,5.928,1.88,6.161c0.032,0.228,0.135,0.438,0.292,0.601
      c0.109,0.334,0.266,0.633,0.463,0.88v0.005C2.49,7.765,2.013,8.07,0.478,8.671C0.193,8.783,0.001,9.065,0.001,9.373v0.892
      c0,0.416,0.335,0.754,0.748,0.754H7.23c0.412,0,0.747-0.338,0.747-0.754V9.373C7.977,9.064,7.785,8.781,7.499,8.67z M6.977,10.019
      H1.001V9.54c2.02-0.801,2.491-1.19,2.612-1.582C3.627,7.91,3.635,7.861,3.635,7.811V7.455c0-0.129-0.05-0.254-0.14-0.347
      C3.319,6.926,3.176,6.656,3.091,6.35c-0.03-0.111-0.108-0.325-0.202-0.392C2.96,5.869,3,5.759,3,5.645V4.847
      c0-0.606,0.314-0.888,0.99-0.888c0.685,0,0.99,0.274,0.99,0.888v0.798c0,0.114,0.029,0.342,0.101,0.43
      C4.986,6.142,4.918,6.239,4.887,6.35C4.802,6.657,4.659,6.926,4.484,7.108c-0.09,0.093-0.14,0.218-0.14,0.347v0.356
      c0,0.05,0.007,0.099,0.022,0.147C4.486,8.351,4.958,8.74,6.977,9.54V10.019z"/>
    <path
      d="M8.021,4.036c2.48-1.442,5.703-1.443,8.185,0c0.079,0.046,0.165,0.068,0.251,0.068
      c0.172,0,0.339-0.089,0.433-0.249c0.139-0.239,0.058-0.545-0.182-0.684c-2.785-1.621-6.405-1.62-9.19,0
      C7.279,3.31,7.199,3.616,7.337,3.855C7.476,4.094,7.781,4.174,8.021,4.036z"/>
    <path
      d="M20.763,11.574c-0.27-0.032-0.522,0.165-0.554,0.44c-0.271,2.389-1.585,4.526-3.604,5.863
      c-0.146,0.097-0.224,0.256-0.224,0.417c0,0.096,0.027,0.191,0.083,0.276c0.097,0.146,0.256,0.224,0.417,0.224
      c0.096,0,0.191-0.027,0.276-0.083c2.265-1.501,3.739-3.9,4.045-6.583c0.002-0.02,0.003-0.039,0.003-0.058
      C21.206,11.819,21.018,11.603,20.763,11.574z"/>
    <path
      d="M4.017,12.014c-0.031-0.274-0.268-0.48-0.553-0.44c-0.255,0.029-0.443,0.245-0.443,0.496
      c0,0.019,0.001,0.038,0.003,0.058c0.305,2.683,1.779,5.083,4.044,6.583c0.085,0.056,0.181,0.083,0.276,0.083
      c0.162,0,0.321-0.078,0.417-0.224c0.056-0.085,0.083-0.181,0.083-0.275c0-0.162-0.079-0.321-0.224-0.418
      C5.603,16.54,4.289,14.402,4.017,12.014z"/>
  </svg>
</template>
